<template>
  <vs-row justify="center">
    <vs-card center>
      <template #title>
        <h3>Login</h3>
      </template>
      <template #text>
        <p>
          Login to check the Repos build status
        </p>
        <br>
        <vs-row justify="center">
          <vs-button
            :animate-inactive="success"
            href="https://github.com/login/oauth/authorize?client_id=fa29cf62113b62bec8da"
            color="github"
          >
            <font-awesome-icon icon="fa-brands fa-github" />&nbsp; Login with GitHub
          </vs-button>
        </vs-row>
      </template>
    </vs-card>
  </vs-row>
</template>

<script>
export default {
  data() {
    return {
      success: false,
      loading: false,
    }
  }
}
</script>
<style lang="scss">

</style>