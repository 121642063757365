<template>
  <div id="app">
    <GithubLogin v-if="$store.state.auth.token === null"/>
    <GithubRepos v-else/>
  </div>
</template>

<script>
import GithubLogin from './components/auth/GithubLogin.vue'
import GithubRepos from './components/repos/GithubRepos.vue'

export default {
  name: 'App',
  components: {
    GithubLogin,
    GithubRepos
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
