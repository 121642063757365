<template>
  <div v-if="!loading">
    <vs-row justify="center" class="repos">
      <vs-card v-for="(repo, $index) in repos" v-bind:key="$index" class="repo" @click="open(repo.html_url)">
        <template #title>
          <h3>{{repo.name}}</h3>
        </template>
        <template #img>
          <img :src="repo.owner.avatar_url" :alt="repo.full_name">
        </template>
        <template #text>
          <p v-if="repo.description !== null">
            {{ repo.description}}
          </p>
          <p v-else>
            No description.
          </p>
        </template>
        <template #interactions>
          <!-- <vs-button default :href="repo.html_url" blank>
            open
          </vs-button> -->
        </template>
      </vs-card>
    </vs-row>
  </div>
  <div v-else>
    <vs-row justify="center">
      <vs-alert color="danger">
        <template #title>
          OPS!
        </template>
        {{error}}
      </vs-alert>
    </vs-row>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      repos: [],
      loading: true,
      error: ''
    }
  },
  methods: {
    open(url) {
      window.open(url)
    }
  },
  mounted() {
    const token = this.$store.state.auth.token
    axios({
      method: 'get',
      url: `https://api.github.com/orgs/ankor-io/repos?type=all`,
      // Set the content type header, so that we get the response in JSON
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then((response) => {
      this.loading = false
      this.repos = response.data
    }).catch((err) => {
      this.loading = false
      this.repos = []
      this.error = 'Error fetching your repos'
      console.error(err)
    })
  }
}
</script>
<style lang="css">
.repos {
  padding: 5px;
}
.repo {
  margin: 5px;
}
</style>